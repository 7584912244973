<template>
    <filter-list
        v-model="list"
        :sortKey="sortKey"
        :hasAction="false"
        ref="list"
        url="/purchase/all"
        :filters="filters"
        labelWidth="78px"
    >
        <template v-for="item in list">
            <purchase-row :key="item.id" :info="item"></purchase-row>
        </template>
    </filter-list>
</template>

<script>
import FilterList from '../../components/page/FilterList.vue';
import PurchaseRow from '../../components/list/PurchaseRow.vue';
import { purchaseTypeOptions, moneyOptions } from '../../utils/variables';
import dayjs from 'dayjs';
export default {
    components: { FilterList, PurchaseRow },
    data() {
        return {
            purchaseTypeOptions,
            moneyOptions,
            fieldOptions: [],
            list: [],
            sortKey: [
                {
                    name: '发布时间',
                    key: 'createdAt'
                },
                {
                    name: '预算',
                    key: 'expectedPrice'
                },
                {
                    name: '浏览量',
                    key: 'view'
                }
            ]
        };
    },
    created() {
        this.init();
    },
    computed: {
        filters() {
            return [
                {
                    name: '采购类型',
                    key: 'purchaseType',
                    list: [...this.purchaseTypeOptions]
                },
                {
                    name: '所属领域',
                    key: 'field',
                    list: [...this.fieldOptions]
                },
                {
                    name: '项目预算',
                    key: 'expectedPrice',
                    list: [...this.moneyOptions]
                },
                {
                    name: '截至时间',
                    key: 'registerDeadline',
                    list: [...this.createdAtOptions]
                }
            ];
        },
        createdAtOptions() {
            return [
                {
                    label: '一周内',
                    value: this.getTimeBefor()
                },
                {
                    label: '一月内',
                    value: this.getTimeOnem()
                },
                {
                    label: '二月内',
                    value: this.getTimeTom()
                },
                {
                    label: '三月内',
                    value: this.getTimeTherrm()
                }
            ];
        }
    },
    methods: {
        getTimeBefor(lng = +1, type = 'w') {
            return [dayjs().format('YYYY-MM-DD'), dayjs().add(lng, type).format('YYYY-MM-DD')].join(',');
        },
        getTimeOnem(lng = +1, type = 'M') {
            return [dayjs().format('YYYY-MM-DD'), dayjs().add(lng, type).format('YYYY-MM-DD')].join(',');
        },
        getTimeTom(lng = +2, type = 'M') {
            return [dayjs().format('YYYY-MM-DD'), dayjs().add(lng, type).format('YYYY-MM-DD')].join(',');
        },
        getTimeTherrm(lng = +3, type = 'M') {
            return [dayjs().format('YYYY-MM-DD'), dayjs().add(lng, type).format('YYYY-MM-DD')].join(',');
        },
        init() {
            this.$http
                .post('/setting/byFlag', { flag: 1 })
                .then(res => {
                    if (res.length > 0) {
                        this.fieldOptions = res.map(item => {
                            return {
                                label: item.name,
                                value: item.id
                            };
                        });
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        }
    }
};
</script>

<style lang="less" scoped></style>
